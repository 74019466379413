import React, { useState } from "react";
import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import { StudentCard, TeacherCard } from "~/components/content-card";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={7}
      lesson={"Lesson 5"}
      color={"ms"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 7</H2>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>During the course of these lessons, you have learned and practiced skills related to analyzing health-related information you find online and in other types of media.</p>
            <p>These skills are important for health literacy. Literacy is not just being able to read the words. Health literacy includes

            <UnorderedList>
              <li>the ability to find information,</li>
              <li>analyze information, and</li>
              <li>understand information.</li>
            </UnorderedList>

            By completing the rubric, you are practicing communicating information, which is also part of health literacy.</p>
            <p>To give you more information about why health literacy is important in our lives, your teacher will share some more information about why people need to have these skills.</p>
            <p>As you hear this new information, try to think about ways that you may use your health-literacy skills in your own life.</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>In this step, students are introduced to the idea that accessing, evaluating, and understanding information is a central part of health literacy, science literacy, and media literacy. As another way to help students understand the relevance of this module to their lives, you could share some information about health literacy with students. Click on the following link to access information that you can project for and discuss with students.</p>
            <Button
              action="secondary"
              color="purple"
              href="/files/hs/lesson_5/hlit_l5_what_is_health_literacy-scl.final.aw.pdf"
              icon="download"
              title="How Is Health Literacy Important in Our Everyday Lives?"
            >
              How is health literacy important in our everyday lives?
            </Button>

            <p>The lessons in this module do not cover all aspects of health literacy discussed in this file, but it should help students see how these skills apply to their lives. It may also be valuable to discuss with students how the ability to analyze information will become more valuable as they get older because they will be increasingly making decisions about their own health. After sharing the information about health literacy with students, ask them if they have any ideas about how they will use their new skills in their own lives.</p>
            <p>You may also want to extend the discussion to include the idea that this type of thinking may be valuable in other areas of their lives. Ask students what other kinds of decisions they may be making in the future that would benefit from analyzing the evidence for. Answers may range from trying to make sense of information in their classes, choosing a career or education pathway, house or apartment to live in, car to buy, or who to vote for.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

